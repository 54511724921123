.reportsCard {
  flex: 0.48;
  background-color: #ebebf0;
  min-height: 18vh;
  max-height: 18vh;
  border-radius: 8px;
  box-shadow: 0 0 2px 2px #f7f7f7;
  padding: 15px;
  position: relative;
  overflow-y: hidden;
}

.headingsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.resportsHeading {
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.dashBoardWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dashBoardWrapper span {
  padding-left: 10px;
}

.reportsdata_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 5px;
}

.data_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.data_wrapper p {
  font-family: Montserrat;
  font-size: 14px;
  margin: 0;
}

.data_tag {
  width: 50%;
}

.data_percentage {
  width: 25%;
  text-align: center;
}

.data_balance {
  width: 25%;
  text-align: right;
  font-weight: 500;
}

@media (max-width: 768px) {
  .reportsCard {
    min-height: 25vh;
    max-height: 25vh;
    padding: 10px;
  }

  .resportsHeading {
    font-size: 12px;
  }

  .dashBoardWrapper span {
    padding-left: 5px;
  }

  .reportsdata_wrapper {
    margin-top: 3px;
  }

  .data_wrapper p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .reportsCard {
    flex: 1 1 100%;
    min-height: 30vh;
    max-height: 30vh;
    padding: 8px;
  }

  .reportsdata_wrapper {
    flex-direction: column;
    position: relative;
    margin-top: 2px;
  }

  .headingsWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashBoardWrapper {
    margin-bottom: 10px;
  }

  .resportsHeading {
    font-size: 10px;
  }

  .dashBoardWrapper span {
    padding-left: 5px;
  }

  .data_wrapper {
    flex-direction: column;
  }

  .data_wrapper p {
    font-size: 10px;
    width: 100%;
  }
}
.negative {
  color: #c90000;
}

.positive {
  color: #117539;
}
