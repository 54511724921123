.modal_overLay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 16px;
    overflow-y: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 60%;
    height: 60%;
  }
  
  /* .content_wrapper {
    display: "flex";
    align-items: center;
    overflow-y: scroll;
  } */