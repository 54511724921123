.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust the opacity here */
  z-index: 1000; /* Ensure it's above other content */
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  width: 80vw;
  max-width: 400px;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeBtn {
  text-align: right;
  color: #353535;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 20px;
}

.heading {
  font-size: 18px;
  font-weight: 700;
}

.inputField {
  height: 40px;
  width: 90%;
  padding: 20px 10px;
  border-radius: 8px;
  border: 2px solid lightgrey;
  font-size: 16px;
  font-family: Montserrat;
  margin: 20px 0px;
  border-radius: 8px;
}

.inputField::placeholder {
  font-family: Montserrat; /* Set the same font family as the input text */
}

.note {
  font-size: 16px;
  color: #353535;
  margin: 10px 0;
}

.suggestion {
  width: 100%;
  padding: 10px;
  background-color: #d9d9d9;
  margin: 5px 0;
  border-radius: 8px;
  cursor: pointer;
}

.createBtn {
  width: 100%;
  margin-top: 40px;
  padding: 10px;
  background-color: #fdd34d;
  border-radius: 8px;
  box-shadow: 0 0 2px 2px lightgrey;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
