p {
  font-family: Montserrat;
}

.card {
  background-color: #ebebf0;
  padding: 15px 20px;
  margin: 10px 0px;
  border-radius: 8px;
  box-shadow: 0 0 2px 2px rgba(211, 211, 211, 0.2);
}

.amount_reason_class {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info_statusBtn_class {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.infoOption {
  font-size: 12px;
  line-height: 20px;

  span {
    font-weight: 500;
    font-size: 14px;
  }
}

.statusText {
  width: 120px;
  padding: 5px 12px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 2px 2px rgba(211, 211, 211, 0.2);
  background-color: #fff;
}

.status_arrowIcon_class {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.onHoverDropdown_wrapper {
  position: absolute;
  right: 10px;
  padding: 5px 10px;
  background-color: #fff;
  margin: 5px 0px;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(211, 211, 211, 0.2);
}

.onHover_option {
  padding: 5px 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
