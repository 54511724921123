/* rightComponent.css */


.chatScreen {
  position: sticky;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Set height to viewport height */
  overflow: hidden; /* Hide overflow to prevent double scrollbars */
}
/* rightComponent.css */


.selectChatText {
  font-family: "Montserrat", sans-serif; /* Montserrat font family */
  font-size: 20px;
  text-align: center; /* Center text */
  padding: 20px;
  background-color: #f0f0f0; /* Light background color */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  align-self: center; /* Center element */
  margin-top: 30%; /* Add margin from the top */
  width: 80%; /* Set width to 80% of the parent container */
  justify-content: center; /* Center text horizontally */
  justify-self: unset;
  align-items: center;
}


/* imageModal.css */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.loading-spinner {
  animation: spin 1s linear infinite;
}
.selected-chat {
  background-color: #e2e2e2; /* Change this color to whatever you like for the selected chat */
}


.imageModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.modalContent {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.modalImage {
  width: 60vw !important;
  height: 60vh !important;
  object-fit: contain;
  margin: 20px;
  border-radius: 10px;
}


.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: black;
}


.chatScreen__header {
  display: flex;
  padding: 20px;
  background-color: #fdd34d; /* Change primary color */
  flex-direction: row;
}


.senderName {
  margin: 0;
  color: #333; /* Change text color */
  padding-left: 20px; /* Add padding to separate sender name */
  align-self: center;
}


.chatScreen__messages {
  flex: 1;


  overflow-y: auto;
  padding: 20px;
  display: flex; /* Ensure the container is flex */
  flex-direction: column; /* Set flex direction to column */
}


.message {
  max-width: 60%;
  min-width: 20%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word; /* Ensure long messages wrap */
  font-family: "Montserrat", sans-serif; /* Change font family */
  font-size: 14px;
}


.message-sender {
  font-size: 12px;
  color: #f33f3f;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.message-timeStamp {
  font-size: 10px;
  color: #333;
  margin-top: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  align-self: flex-end;
  margin-left: auto;
}
.chatScreen__input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0; /* Change input background color */
  justify-content: space-between; /* Separate input field and send button */
}
.inputAndImage {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #d9d9d9; /* Change input field background color */
  height: 80px;
  border-radius: 10px;
}
.attachmentButton {
  padding: 20px;
  align-self: center;
  height: 65px;
  margin-right: 10px;
  width: 65px;
  border-radius: 8px;
  background-color: #fff; /* Change send button background color */
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth color transition */
}
.inputField {
  width: 96%;
  height: 100px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  margin-right: 10px;
  background-color: #d9d9d9; /* Change input field background color */
  opacity: 1; /* Adjusted opacity to make the input field visible */
  transition: opacity 0.3s; /* Add transition for opacity */
  font-family: "Montserrat", sans-serif; /* Change font family */
}
.input__container {
  display: flex;
  align-items: center;
}


.input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  height: 65px;
  border-radius: 10px;
  border: 1px solid #ccc;
  outline: none;
  resize: none; /* Disable manual resizing */
  max-height: 100px; /* Set a max-height for the textarea */
  overflow-y: auto; /* Add vertical scrolling */
}
.sendButton {
  width: 6%;
  height: 80px;
  padding: 10px;
  border-radius: 10px;
  background-color: #d9d9d9; /* Change send button background color */
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth color transition */
}
.chatImage {
  height: 40vh;
  width: 30vh;
  border-radius: 10px;
}
.sendButton:hover {
  background-color: #fdd34d; /* Darker shade on hover */
}


.sendButton:active {
  transform: translateY(1px); /* Add slight press effect */
}
.attachmentButton:hover {
  background-color: #fdd34d; /* Darker shade on hover */
}


.attachmentButton:active {
  transform: translateY(1px); /* Add slight press effect */
}



