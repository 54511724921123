.balanceCard {
  flex: 0.48;
  min-height: 18vh;
  max-height: 18vh;
  background-color: #fdd34d;
  border-radius: 8px;
  box-shadow: 0 0 2px 2px #f7f7f7;
  padding: 15px;
  position: relative;
}

.dates_reports_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.icon_text_aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon_text_aligner span {
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.transaction_balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  right: 3px;
}

.balaceOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.optionHeading {
  font-size: 12px;
  font-family: Montserrat;
}

.optionMoney {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
}

@media (max-width: 768px) {
  .balanceCard {
    min-height: 25vh;
    max-height: 25vh;
    padding: 10px;
  }

  .icon_text_aligner span {
    font-size: 12px;
  }

  .optionHeading {
    font-size: 10px;
  }

  .optionMoney {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .balanceCard {
    flex: 1 1 100%;
    min-height: 30vh;
    max-height: 30vh;
    padding: 8px;
  }

  .transaction_balance {
    flex-direction: column;
    position: relative;
    right: 0;
    bottom: 5px;
  }

  .dates_reports_wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .icon_text_aligner {
    margin-bottom: 10px;
  }

  .icon_text_aligner span {
    font-size: 10px;
  }

  .optionHeading {
    font-size: 8px;
  }

  .optionMoney {
    font-size: 12px;
  }
}
