.chat-list {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
}
.chat-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  min-width: 100%;
  height: 120px;
}
.highlight {
  background-color: yellow;
}

.chat-list-title {
  font-size: 18px;
  font-weight: 600;
  font-family: Montserrat;
}
.chat-list-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  width: 50%;
  min-width: 50%;
}
.chat-list-search input {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
}
.chat-list-body {
  overflow-y: scroll;
  height: 80vh;
  width: 100%;
}
.chat-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  padding-left: 20px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}
.chat-list-item:hover {
  box-shadow: 0px 0px 3px 3px #e3e3e3;
  /* padding: 20px 25px; */
}
::-webkit-scrollbar {
  width: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chat-list-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat-list-item-name {
  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;
  margin-left: 10px;
}
.chat-list-item-content {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
  margin-left: 10px;
}
.chat-list-item-text {
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
  margin-left: 10px;
}
.last-time {
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat;
  margin-left: auto;
}
