.container {
  width: 100%;
  height: 100vh;
  /* padding-top: 20px; */
}

.project_select_alert {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 30%;
}

.alert_note {
  width: 60%;
  text-align: center;
  background-color: #f0f0f0;
  padding: 10px 30px;
  border-radius: 8px;
  font-size: 24px;
  font-family: Montserrat;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  color: #353535;
}

.navTabs_wrapper {
  height: 20vh;
  width: 100%;
  padding: 0 15px;
}

.navTabs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

.tabBtn {
  font-size: 18px;
  font-family: Montserrat;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tabBtn.active {
  border-bottom-color: black;
}

.inputWrapper {
  border-bottom: 0.5px solid gray;
}

.searchInputTab {
  border: none;
  outline: none;
  height: 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
}

.searchIcon {
  margin-left: 10px;
}

.tabContent {
  padding: 0px 20px;
}
