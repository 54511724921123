.modal_overLay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.content {
  min-width: 30%;
  max-width: 45%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 25px;
  overflow-y: scroll;
}

.content_width70 {
  min-width: 60%;
  /* height: 65%; */
  /* max-height: 80vh; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  overflow-y: scroll;
}

.mainBtns_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mainBtn {
  width: 100px;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  font-family: Montserrat;
  box-shadow: 0 0 2px 2px #e3e3e3;
  cursor: pointer;
  border: 1px solid gray;
}

.form_entries_class {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.field_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 30px 0px;

  .inputLabel {
    font-size: 14px;
    font-family: Montserrat;
    position: absolute;
    top: -10px;
    left: 20px;
    background-color: #fff;
    padding: 0px 8px;
  }

  span {
    position: absolute;
    top: 10px;
    font-size: large;
    font-weight: 700;
    margin: 0 10px;
    font-family: Montserrat;
  }
}

.tags_wrapper {
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: absolute;
  top: 50px;
  background-color: #fff;
  z-index: 10;
  border: 2px solid #ebebf0;
  border-radius: 8px;
}

.tagName {
  min-height: 30px;
  text-wrap: wrap;
  font-family: Montserrat;
  font-size: 14px;
  margin: 5px 10px;
  background-color: #ebebf0;
  padding: 3px 0px 3px 5px;
  border-radius: 8px;
  cursor: pointer;
}

.date_calender_Icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .selectedData_class {
    font-family: Montserrat;
    font-size: 12px;
    padding-right: 10px;
  }
}

.calendar_wrapper {
  width: "100%";
  position: absolute;
  z-index: 10;
  right: 0;
  top: 140px;
}

.amount_calender_field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.entryField {
  height: 40px;
  padding: 20px 10px;
  border-radius: 8px;
  border: 2px solid lightgrey;
  font-size: 16px;
  font-family: Montserrat;
}

.amountInput {
  width: 100%;
}

.amountInput::-webkit-outer-spin-button,
.amountInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cls_addentry_btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.clsBtn {
  border: none;
  background-color: #fff;
  color: #757575;
  font-family: Montserrat;
  font-weight: 400;
  font-size: medium;
  cursor: pointer;
}

.addEntryBtn_imgSpinner_class {
  position: relative;
  background-color: #2f2e41;
  box-shadow: 0 0 2px 2px #e3e3e3;
  width: 120px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px 20px;
}

.addEntryBtn {
  text-wrap: nowrap;
  color: #fff;
  font-weight: 300;
  font-family: Montserrat;
  font-size: 14px;
}

.card {
  background-color: #ebebf0;
  border-radius: 8px;
  margin: 10px 0px;
  cursor: pointer;
  padding: 10px;
}

.flex_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.reason_amount_font {
  font-size: 16px;
  font-weight: 600;
  color: #353535;
  font-family: Montserrat;
}

.attachmentIcon_amount_class {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0.35;
}

.date_class {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;

  span {
    font-size: 20px;
    margin: 0 5px;
    font-weight: 600;
  }
}

.tag {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;

  span {
    padding: 0 5px;
  }
}

.entriesWrapper {
  width: 45%;
  min-height: 65vh;
  max-height: 70vh;
  overflow-y: scroll;
}
