.mainContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #d9deec;
}

.login_container {
  width: 350px;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  padding: 30px 20px;
  background-color: #fff;
}
.pointer {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
  color: gray;
}
.welcome_heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.welcome_text {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 5px;
  font-family: Montserrat;
}

.compName {
  font-size: 25px;
  font-weight: 600;
  font-family: Montserrat;
}

.login_text {
  font-size: 18px;
  font-weight: 500;
  font-family: Montserrat;
}

.inputField {
  width: 100%;
  height: 40px;
  margin: 8px 0px;
  font-size: large;
  font-family: Montserrat;
  padding-left: 5px;
}

.continueBtn {
  width: 100%;
  height: 40px;
  background-color: #4267b2;
  margin-top: 25px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Montserrat;
}