.spinner {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container_wrapper {
  height: 60vh;
  overflow-y: scroll;
  scrollbar-width: none;
  margin: 5px 0px;
}

.card {
  background-color: #ebebf0;
  border-radius: 8px;
  margin: 10px 0px;
  cursor: pointer;
  padding: 10px;
}

.flex_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.reason_amount_font {
  font-size: 16px;
  font-weight: 500;
  color: #353535;
  font-family: Montserrat;
}

.attachmentIcon_amount_class {
  flex: 0.3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date_class {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;

  span {
    font-size: 20px;
    margin: 0 5px;
    font-weight: 600;
  }
}

.tag {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;

  span {
    padding: 0 5px;
  }
}

.menu_options_wrapper {
  position: absolute;
  right: 20px;
  top: 30px;
  background-color: #fff;
  width: 25vh;
  padding: 5px 15px;
  z-index: 3;
  border-radius: 8px;
  box-shadow: 0 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.menu_options {
  font-size: 10px;
  margin: 10px 0px;
  font-family: Montserrat;
  font-weight: 500;
}
