.projects_wrapper {
  overflow-y: scroll;
  height: 68vh;
  width: 100%;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.project_card {
  padding: 10px 15px;
  border: 0.4px solid lightgray;
  cursor: pointer;
}

.project_card:hover {
  box-shadow: 0px 0px 4px 4px #e3e3e3;
  padding: 12px 15px;
}

.selectedProject_card {
  padding: 10px 15px;
  border: 0.4px solid lightgray;
  cursor: pointer;
  background-color: #ebebf0;
}

.name_class {
  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;
  padding: 5px 0px;
}

.role_class {
  font-size: 12px;
  font-family: Montserrat;
}

.finances_class {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 0px;
}

.subFinance_class {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.finances_heading {
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat;
}

.finances_keys {
  font-size: 16px;
  font-weight: 600;
}

.inputWrapper {
  border-bottom: 0.5px solid gray;
  margin: 20px 10px 10px 10px;
  display: flex;
  align-items: center;
}

.searchInputTab {
  width: 90%;
  border: none;
  outline: none;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
}

@media (max-width: 768px) {
  .name_class {
    font-size: 14px;
  }

  .role_class {
    font-size: 11px;
  }

  .finances_heading {
    font-size: 11px;
  }

  .finances_keys {
    font-size: 14px;
  }

  .searchInputTab {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .projects_wrapper {
    height: 70vh;
  }

  .name_class {
    font-size: 12px;
  }

  .role_class {
    font-size: 10px;
  }

  .finances_heading {
    font-size: 10px;
  }

  .finances_keys {
    font-size: 12px;
  }

  .searchInputTab {
    font-size: 12px;
  }

  .inputWrapper {
    margin: 15px 5px 5px 5px;
  }

  .project_card,
  .selectedProject_card {
    padding: 8px 10px;
  }

  .project_card:hover {
    padding: 10px 10px;
  }
}
