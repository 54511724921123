.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 40%;
    height: 50%;
    object-fit: contain;
    margin-right: 25px;
  }
  p {
    color: #353535;
    font-size: 18px;
    font-weight: 600;
    font-family: Montserrat;
  }
}

.left_class {
  width: 30%;
}

.addProjBtn_biMenu {
  background-color: #2f4858;
  width: 100%;
  min-height: 20%;
  padding: 35px 15px;
  position: sticky;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.addProjBtn {
  color: #fff;
  cursor: pointer;
  font-family: Montserrat;
}

.chat_Icon {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 80px;
  margin-left: 23%;
  background-color: #fdd34d;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_class {
  width: 70%;
}

.otpInput {
  display: flex;
  justify-content: space-between;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .left_class {
    width: 100%;
  }

  .right_class {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .loading img {
    width: 60%;
    height: auto;
    margin-right: 10px;
  }

  .loading p {
    font-size: 16px;
  }

  .chat_Icon {
    margin-left: 20%;
  }
}
