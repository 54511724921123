.spinner {
  width: 100%;
  height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topcontainer {
  background-color: #fdd34d;
  margin: 0px 20px;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  max-height: 25vh;
}

.dropDown_headings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.left_elemets {
  flex: 0.5;
}

.topcontainer_headings {
  flex: 0.3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.icon_text_aligner {
  cursor: pointer;
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
  }
}

.flex_row {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-family: Montserrat;
    font-weight: 500;
  }
}

.cards_wrapper {
  margin: 0px 20px;
  height: 55vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.cards_wrapper::-webkit-scrollbar {
  width: 0px;
}

.mainBtns_wrapper {
  position: absolute;
  bottom: 50px;
  right: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mainBtn {
  width: 100px;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: Montserrat;
  box-shadow: 0 0 3px 3px #e3e3e3;
  cursor: pointer;
}
